import { InformationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { ChangeEvent, FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import Tooltip from 'src/components/common/Tooltip';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import { NumberFieldControlled } from 'src/components/fields/NumberField';
import { SelectControlled } from 'src/components/fields/Select';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import FormDataRow from 'src/components/FormDataRow';
import { colorSchemesNames } from 'src/constants/colorSchemes';
import getParentDomain from 'src/helpers/getParentDomain';
import { TenantSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

export type TenantFormProps = {
  defaultValues?: TenantSchema;
  className?: string;
  onSubmit: SubmitHandler<TenantSchema>;
};

const parentDomain = getParentDomain();

const TenantForm: FC<TenantFormProps> = ({ className, defaultValues, onSubmit }) => {
  const form = useForm<TenantSchema>({
    resolver: zodResolver(TenantSchema),
    defaultValues: {
      isDemo: false,
      ...defaultValues,
    },
  });

  const { control, handleSubmit, setValue, register } = form;
  const handleTenantNameChange = (value: string | null | undefined): void => {
    if (!value) return setValue('tenantDomain', `.${parentDomain}`);

    setValue('tenantDomain', `${value}.${parentDomain}`);
  };

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setValue('file', e.target.files?.[0]),
    [setValue],
  );

  return (
    <FormProvider {...form}>
      <div className={clsx(className, 'flex-col w-full')}>
        <form className='flex flex-col w-full gap-y-4' onSubmit={handleSubmit(onSubmit)}>
          <TextFieldControlled
            control={control}
            extraOnChange={handleTenantNameChange}
            name='tenantName'
            asterisk
            label={<FormattedMessage id='app.tenant.tenant_name' />}
          />
          <TextFieldControlled
            disabled
            control={control}
            name='tenantDomain'
            asterisk
            label={<FormattedMessage id='app.tenant.tenant_domain' />}
          />
          <TextFieldControlled
            control={control}
            name='applicationTitle'
            label={
              <div className='flex'>
                <FormattedMessage id='app.tenant.application_title' />
                <Tooltip
                  text={
                    <span className='inline-block max-w-sm w-max'>
                      <FormattedMessage id='app.tenants_page.tooltip.application_name' />
                    </span>
                  }
                >
                  <InformationCircleIcon className='w-6' />
                </Tooltip>
              </div>
            }
          />
          <SelectControlled
            options={colorSchemesNames}
            getOptionLabel={(v) => v}
            getOptionValue={(v) => v}
            control={control}
            label={<FormattedMessage id='app.tenant.color_theme' />}
            name='colorTheme'
          />
          <NumberFieldControlled
            control={control}
            name='maxUserCount'
            label={<FormattedMessage id='app.tenant.max_user_count' />}
          />
          <CheckboxControlled control={control} name='isDemo' label={<FormattedMessage id='app.tenant.is_demo' />} />
          <FormDataRow label={<FormattedMessage id='app.tenant.logo' />}>
            <input
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ref={register('file') as any}
              onChange={handleFileChange}
              type='file'
              accept='image/*'
            />
          </FormDataRow>
          <NewItemPopupFooter />
        </form>
      </div>
    </FormProvider>
  );
};

TenantForm.displayName = 'TenantForm';

export default TenantForm;
