import { Cell, flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { ExtendedColumnDef } from 'src/types/table';

export type TableCellProps<T> = {
  cell: Cell<T, unknown>;
  onClick?: () => void;
};

const TableCell = <T extends { id: number }>({ cell, onClick }: PropsWithChildren<TableCellProps<T>>): JSX.Element => {
  const { size, maxSize, noWrap } = cell.column.columnDef as ExtendedColumnDef<T>;

  return (
    <td
      style={{ width: size, maxWidth: maxSize }}
      onClick={onClick}
      className={clsx('py-3 px-2 min-h-[85px] text-[1.075rem]', noWrap ? 'whitespace-nowrap' : 'whitespace-normal')}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

export default TableCell;
