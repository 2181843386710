import clsx from 'clsx';
import { format, parse } from 'date-fns';
import React, { ReactNode, forwardRef } from 'react';
import { FieldProps } from 'src/types/commonTypes';
import { FieldValues, FieldPath, UseControllerProps, Controller } from 'react-hook-form';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';

export type DatePickerProps = Omit<
  FieldProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement, Date | null>,
  'onChange'
> & {
  error?: ReactNode;
  label?: ReactNode;
  labelClassName?: string;
  labelTextClassName?: string;
  hideErrorMessage?: boolean;
  asterisk?: boolean;
  onChange?: (date: Date | null) => void;
};

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>((props, ref) => {
  const {
    value,
    onChange,
    asterisk,
    label,
    labelClassName,
    labelTextClassName,
    className,
    disabled,
    hideErrorMessage,
    error,
    ...rest
  } = props;

  return (
    <div className='flex flex-col w-full'>
      <label className='flex flex-col gap-y-1'>
        {label && (
          <FieldLabel asterisk={asterisk} className={labelClassName} textClassName={labelTextClassName}>
            {label}
          </FieldLabel>
        )}
        <input
          type='date'
          className={clsx('w-full h-10 rounded border border-gray-300 px-3', className)}
          value={value ? format(value, 'yyyy-MM-dd') : ''}
          disabled={disabled}
          onChange={(e) => {
            const date = e.target.value;

            if (!date) {
              onChange?.(null);
              return;
            }

            const prevDate = value ?? new Date();

            const parsedDate = parse(date, 'yyyy-MM-dd', prevDate);
            parsedDate.setHours(
              prevDate.getHours(),
              prevDate.getMinutes(),
              prevDate.getSeconds(),
              prevDate.getMilliseconds(),
            );

            onChange?.(parsedDate);
            return;
          }}
          {...rest}
          ref={ref}
        />
      </label>
      {!hideErrorMessage && error && <FieldError>{error}</FieldError>}
    </div>
  );
});

export default DatePicker;

type DatePickerControlledProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<DatePickerProps, 'value' | 'onChange' | 'name' | 'error'> & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: TName;
};

export const DatePickerControlled = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...textFieldProps
}: DatePickerControlledProps<TFieldValues, TName>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker {...textFieldProps} {...field} name={name} error={error?.message} />
      )}
    />
  );
};
