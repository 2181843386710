import { formatISO, parseISO } from 'date-fns';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'src/components/fields/DatePicker';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import useGetIsHiddenProductFormField from 'src/hooks/formsConfig/useGetIsHiddenProductFormField';
import { OrderSchema } from '../schema';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';

export type ServiceNewProductFormProps = {
  index: number;
};

const ServiceNewProductForm: FC<ServiceNewProductFormProps> = ({ index }) => {
  const { control } = useFormContext<OrderSchema>();

  const { data: config } = useCurrentTenantConfig();
  const getIsHiddenField = useGetIsHiddenProductFormField();

  return (
    <>
      <TextFieldControlled
        control={control}
        name={`orderProducts.${index}.product.productName`}
        asterisk
        label={<FormattedMessage id='app.product.name' />}
      />

      {!getIsHiddenField('productProducer') && (
        <TextFieldControlled
          control={control}
          name={`orderProducts.${index}.product.productProducer`}
          label={<FormattedMessage id='app.product.producer' />}
        />
      )}

      {!getIsHiddenField('productSerialNumber') && (
        <TextFieldControlled
          control={control}
          name={`orderProducts.${index}.product.productSerialNumber`}
          label={<FormattedMessage id='app.product.serial_number' />}
        />
      )}

      {config?.productForm?.showProductSerialNumber2 && (
        <TextFieldControlled
          control={control}
          name={`orderProducts.${index}.product.productSerialNumber2`}
          label={<FormattedMessage id='app.product.serial_number2' />}
        />
      )}

      {config?.productForm?.showTechnology && (
        <TextFieldControlled
          control={control}
          name={`orderProducts.${index}.product.technology`}
          label={<FormattedMessage id='app.product.technology' />}
        />
      )}

      {!getIsHiddenField('productNote') && (
        <TextFieldControlled
          control={control}
          name={`orderProducts.${index}.product.productNote`}
          label={<FormattedMessage id='app.product.documentation_note' />}
        />
      )}

      {!getIsHiddenField('warranty') && (
        <Controller
          control={control}
          name={`orderProducts.${index}.product.warranty`}
          render={({ field: { value, ...rest }, fieldState }) => (
            <DatePicker
              {...rest}
              label={<FormattedMessage id='app.product.warranty' />}
              onChange={(v) => rest.onChange(v ? formatISO(v) : null)}
              value={value ? parseISO(value) : null}
              error={fieldState.error?.message}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name={`orderProducts.${index}.product.inUseFrom`}
        render={({ field: { value, ...rest }, fieldState }) => (
          <DatePicker
            {...rest}
            label={<FormattedMessage id='app.product.in_use_from' />}
            onChange={(v) => rest.onChange(v ? formatISO(v) : null)}
            value={value ? parseISO(value) : null}
            error={fieldState.error?.message}
          />
        )}
      />
    </>
  );
};

export default ServiceNewProductForm;
