import { range } from 'ramda';
import { FC, useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactListResponse, ContactResponse, useApiClient } from 'src/api';
import useContact from 'src/api/hooks/queries/useContact';
import useSupportedLanguages from 'src/api/hooks/queries/useSupportedLanguages';
import { AutocompleteAsyncControlled } from 'src/components/fields/AutocompleteAsync';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import { PhoneInputLocalizedControlled } from 'src/components/fields/PhoneInputLocalized';
import { SelectControlled } from 'src/components/fields/Select';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import { CURRENCY_OPTIONS } from 'src/constants/currency';
import { MessageId } from 'src/types/commonTypes';
import { BRANCH_FIELDS, COMPANY_FIELDS, EMPLOYEE_FIELDS } from '../constants';
import { OrderSchema } from '../schema';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import { INTERNAL_CATEGORY_OPTIONS } from 'src/constants/internalCategories';
import AresIcoTextfield from 'src/pages/ContactPage/ContactForm/AresIcoTextfield';
import CollapsableSection from 'src/components/CollapsableSection';

const ServiceCustomerForm: FC<{ disableCreateNew?: boolean }> = ({ disableCreateNew }) => {
  const intl = useIntl();
  const apiClient = useApiClient();
  const { control, setValue, resetField, watch } = useFormContext<OrderSchema>();
  const { fields: productFields, remove } = useFieldArray({ name: 'orderProducts', control });

  const companyId = watch('company.companyId');
  const createNewCompany = watch('company.createNewCompany');
  const category = watch('category');

  const { data: tenantConfig } = useCurrentTenantConfig();
  const { data: contact } = useContact(companyId ?? 0, { enabled: !!companyId });
  const defaultContactOptions = useMemo(() => (contact ? [contact as ContactListResponse] : []), [contact]);

  const { data } = useSupportedLanguages();

  const getContacts = async (query: string): Promise<ContactListResponse[]> => {
    const data = await apiClient.contact.getContactList({
      filterString: query,
      category,
      pageSize: 20,
      pageIndex: 0,
      withDeleted: false,
    });
    return data.items;
  };

  const handleContactChange = async (contactId: number | null): Promise<void> => {
    setValue('branch.branchId', null as unknown as number);
    BRANCH_FIELDS.forEach((field) => resetField(`branch.branch.${field}`));

    setValue('employee.employeeId', null as unknown as number);
    EMPLOYEE_FIELDS.forEach((field) => resetField(`employee.employee.${field}`));

    resetField('orderProducts');

    if (!contactId) return;

    const data = await apiClient.contact.getContact({ id: contactId });
    COMPANY_FIELDS.forEach((field) => setValue(`company.company.${field}`, data[field]));
  };

  const hanldeCreateNewCompanyChange = useCallback(
    (value: boolean | null) => {
      if (!value) {
        setValue('branch.createNewBranch', false);
        setValue('employee.createNewEmployee', false);
        range(1, productFields.length).forEach((index) => remove(index));
        setValue(`orderProducts.0.createNewProduct`, false);
        return;
      }

      resetField('company.companyId');
      COMPANY_FIELDS.forEach((field) => resetField(`company.company.${field}`));

      if (category === ContactResponse.category.B2B) {
        setValue('branch.createNewBranch', true);
        setValue('employee.createNewEmployee', true);
      }

      range(0, productFields.length).forEach((index) => setValue(`orderProducts.${index}.createNewProduct`, true));
    },
    [setValue, category],
  );

  return (
    <div className='flex flex-col mb-16 gap-y-4'>
      <div className='font-bold text-gray-800 text-2xl'>
        <FormattedMessage id='app.order.section_title.contact' />
      </div>

      {!disableCreateNew && (
        <CheckboxControlled
          control={control}
          name='company.createNewCompany'
          extraOnChange={hanldeCreateNewCompanyChange}
          label={<FormattedMessage id='app.order.new_customer' />}
        />
      )}

      {!createNewCompany && (
        <AutocompleteAsyncControlled
          control={control}
          name='company.companyId'
          asterisk
          label={<FormattedMessage id='app.order.section_title.contact' />}
          extraOnChange={(v: number | null) => {
            handleContactChange(v);
          }}
          getOptions={getContacts}
          getOptionLabel={(opt) => `${opt.companyName}${opt.vat ? ` - ${opt.vat}` : ''}`}
          getOptionValue={(opt) => opt.id}
          defaultOptions={defaultContactOptions}
        />
      )}

      <CollapsableSection disabled={createNewCompany} className='gap-y-4'>
        <TextFieldControlled
          control={control}
          name='company.company.companyName'
          disabled={!createNewCompany}
          asterisk
          label={<FormattedMessage id='app.order.company_name' />}
        />

        {category === ContactResponse.category.B2B && (
          <>
            {createNewCompany ? (
              <Controller
                name='company.company.in'
                render={({ field, fieldState }) => (
                  <AresIcoTextfield
                    {...field}
                    label={<FormattedMessage id='app.common.ico' />}
                    onIcoSelect={(data) => {
                      setValue('company.company.companyName', data.companyName);
                      setValue('company.company.city', data.city);
                      setValue('company.company.street', data.street);
                      setValue('company.company.zip', data.zip);
                      setValue('company.company.vat', data.vatNumber);
                    }}
                    error={fieldState.error?.message}
                  />
                )}
              />
            ) : (
              <TextFieldControlled
                label={<FormattedMessage id='app.common.ico' />}
                control={control}
                name='company.company.in'
                disabled
              />
            )}
            <TextFieldControlled
              control={control}
              name='company.company.vat'
              disabled={!createNewCompany}
              label={<FormattedMessage id='app.common.vat' />}
            />
          </>
        )}
        <TextFieldControlled
          control={control}
          name='company.company.city'
          disabled={!createNewCompany}
          asterisk
          label={<FormattedMessage id='app.common.city' />}
        />
        <TextFieldControlled
          control={control}
          name='company.company.street'
          disabled={!createNewCompany}
          asterisk
          label={<FormattedMessage id='app.common.street' />}
        />
        <TextFieldControlled
          control={control}
          name='company.company.zip'
          disabled={!createNewCompany}
          asterisk
          label={<FormattedMessage id='app.common.zip' />}
        />
        <PhoneInputLocalizedControlled
          control={control}
          name='company.company.phoneNumber'
          disabled={!createNewCompany}
          asterisk
          label={<FormattedMessage id='app.common.phone' />}
        />
        <TextFieldControlled
          control={control}
          name='company.company.email'
          disabled={!createNewCompany}
          asterisk
          label={<FormattedMessage id='app.common.email' />}
        />
        {tenantConfig?.contactForm?.showInternalCategory && (
          <SelectControlled
            label={<FormattedMessage id='app.contact.internal_category' />}
            options={INTERNAL_CATEGORY_OPTIONS}
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            control={control}
            disabled={!createNewCompany}
            name='company.company.internalCategory'
          />
        )}
        {tenantConfig?.contactForm?.showContractNumber && (
          <TextFieldControlled
            control={control}
            name='company.company.contractNumber'
            disabled={!createNewCompany}
            label={<FormattedMessage id='app.contact.contract_number' />}
          />
        )}

        <SelectControlled
          control={control}
          name='company.company.preferredCurrency'
          asterisk
          label={<FormattedMessage id='app.common.preferred_currency' />}
          disabled={!createNewCompany}
          options={CURRENCY_OPTIONS}
          getOptionLabel={(option) => option}
          getOptionValue={(option) => option}
        />

        <SelectControlled
          control={control}
          name='company.company.preferredLanguage'
          asterisk
          label={<FormattedMessage id='app.common.preferred_language' />}
          disabled={!createNewCompany}
          options={data ?? []}
          getOptionLabel={(option) =>
            intl.formatMessage({ id: `app.language.${option}` as MessageId, defaultMessage: option })
          }
          getOptionValue={(option) => option}
        />
      </CollapsableSection>
    </div>
  );
};

export default ServiceCustomerForm;
